.App {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
  text-align: center;
}

.App-header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 6px 6px 6px 60px;
	text-align: right;

	@media(min-width: 1150px) {
		justify-content: flex-start;
		padding: 0 150px 6px 0;
		text-align: left;
	}
}

.App-logo {
	pointer-events: none;
	height: 100%;
	width: 100%;
}

.App-deco-ship {
	display: none;
	position: absolute;
	top: -150px;
	right: 0;
	width: 150px;

	@media(min-width: 1150px) {
		display: block;
	}
}

.App-deco-fishes {
	width: 150px;
	padding: 25px 0 0 0;
}
