$transform-time: .2s;
$burger-dimen: 35px;
$burger-color: #333;

$__scale: .66;
$__rotate: 180deg;
$__border-radius: .1 * $burger-dimen;

.nav-burger {
	position: relative;
	width: $burger-dimen;
	height: $__scale * $burger-dimen;
	cursor: pointer;
	transition: transform $transform-time ease-in;

  span {
    display: block;
    position: absolute;
    width: 100%;
    height: $burger-dimen / 10;
    top: 50%;
    left: 0;
    background: $burger-color;
    border-radius: $__border-radius;
  }

  span::after, span::before {
    content: '';
    position: absolute;
    background: $burger-color;
    border-radius: $__border-radius;
    width: 100%;
    height: 100%;
    left: 0;
    transition: transform $transform-time ease-in;
  }

  span::before {
    transform: translateY(-6 * $burger-dimen / 20);
  }

  span::after {
    transform: translateY(6 * $burger-dimen / 20);
	}
	
	&.transformed {
		span, span::before, span::after {
			background: #458cd9;
		}

		&.left {
			transform: rotate($__rotate);
		}
	
		&.top {
			transform: rotate(3 * $__rotate / 2);
		}
		
		&.bottom {
			transform: rotate($__rotate / 2);
		}
	
		&.right {
			transform: rotate(2 * $__rotate);
		}
	}

  &.transformed span::before {
    transform: translateY(-$burger-dimen / 5)
               translateX(6 * $burger-dimen / 20)
               rotate($__rotate / 4)
               scaleX($__scale);
  }

  &.transformed span::after {
    transform: translateY($burger-dimen / 5)
               translateX(6 * $burger-dimen / 20)
               rotate(-$__rotate / 4)
               scaleX($__scale);
  }
}
