.download-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: inherit;
	height: inherit;
	padding: 15px 0;
}

.download-button svg {
	fill: inherit;
	margin-right: 7px;
	width: 35px;
	height: 35px;
}