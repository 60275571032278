.App {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
}

.App-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 6px 6px 6px 60px;
  text-align: right;
}

@media (min-width: 1150px) {
  .App-header {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 0 150px 6px 0;
    text-align: left;
  }
}

.App-logo {
  pointer-events: none;
  height: 100%;
  width: 100%;
}

.App-deco-ship {
  display: none;
  position: absolute;
  top: -150px;
  right: 0;
  width: 150px;
}

@media (min-width: 1150px) {
  .App-deco-ship {
    display: block;
  }
}

.App-deco-fishes {
  width: 150px;
  padding: 25px 0 0 0;
}
