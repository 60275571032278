.display-link {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: fit-content;
	min-height: 40px;
	margin: 20px 0;
	color: var(--mmk-color-link);
	transition: color 250ms ease;
}

.display-link:hover {
	color: var(--mmk-color-link-hover);
}

.display-link::before {
	content: '\2192';
	margin-right: 0;
	font-weight: bold;
	font-size: 0;
	transition: font-size 350ms ease;
}

.display-link:hover::before {
	font-size: 1.1rem;
	margin-right: 3px;
}

.display-link > div {
	color: inherit;
	font-size: 1.25rem;
	font-weight: bold;
	text-decoration: underline;
}

.display-link a, .display-link:hover a {
	color: inherit;
	transition: inherit;
}
