@import url('https://fonts.googleapis.com/css2?family=Commissioner&display=swap');

:root {
	--mmk-color-link: #f58810;
	--mmk-color-link-hover: #8c2b99;
}

*, *::before, *::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Commissioner', 'Segoe UI',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100vw;
	height: 100vh;
	background: #fdfeff;
	font-size: 16px;
}

body > #root {
	width: 100%;
	height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
