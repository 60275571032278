.nav-burger {
  position: relative;
  width: 35px;
  height: 23.1px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s ease-in;
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
}

.nav-burger span {
  display: block;
  position: absolute;
  width: 100%;
  height: 3.5px;
  top: 50%;
  left: 0;
  background: #333;
  border-radius: 3.5px;
}

.nav-burger span::after, .nav-burger span::before {
  content: '';
  position: absolute;
  background: #333;
  border-radius: 3.5px;
  width: 100%;
  height: 100%;
  left: 0;
  -webkit-transition: -webkit-transform 0.2s ease-in;
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
}

.nav-burger span::before {
  -webkit-transform: translateY(-10.5px);
          transform: translateY(-10.5px);
}

.nav-burger span::after {
  -webkit-transform: translateY(10.5px);
          transform: translateY(10.5px);
}

.nav-burger.transformed span, .nav-burger.transformed span::before, .nav-burger.transformed span::after {
  background: #458cd9;
}

.nav-burger.transformed.left {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.nav-burger.transformed.top {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.nav-burger.transformed.bottom {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.nav-burger.transformed.right {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.nav-burger.transformed span::before {
  -webkit-transform: translateY(-7px) translateX(10.5px) rotate(45deg) scaleX(0.66);
          transform: translateY(-7px) translateX(10.5px) rotate(45deg) scaleX(0.66);
}

.nav-burger.transformed span::after {
  -webkit-transform: translateY(7px) translateX(10.5px) rotate(-45deg) scaleX(0.66);
          transform: translateY(7px) translateX(10.5px) rotate(-45deg) scaleX(0.66);
}
